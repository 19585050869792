/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkFilter.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkflowInstanceContext.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json#"},"CountOnly":{"type":"boolean"},"CountOnlyDefault":{"type":"string"},"IgnoreStateOwnerExpire":{"type":"boolean"},"IgnoreStateOwnerExpireDefault":{"type":"string"},"ResourceID":{"type":"string"},"StateOwner":{"type":"integer"},"WorkEntityID":{"type":"string"},"WorkState":{"type":"string"},"WorkTypeID":{"items":{"type":"string"},"type":"array"},"XeWorkFilter":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkFilter.json#"},"type":"array"},"XeWorkflowInstanceContext":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkflowInstanceContext.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
