/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkFilter.json#"},"FilterName":{"type":"string","maxLength":200},"FilterValue":{"type":"string","maxLength":200}},"$id":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkFilter.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest$XeWorkFilter.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
