/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.xmlobjects.XeFileStorageMergeRequest.json#"},"FileID":{"items":{"type":"integer"},"type":"array"},"IncludeTOC":{"type":"boolean"},"MatchFileName":{"type":"string","maxLength":100},"MatchFileTime":{"format":"date","nullable":true,"type":"string"},"MatchFileType":{"type":"string","maxLength":10},"MergedPDFName":{"type":"string"},"SourceFileIDList":{"type":"string"},"SubDirectory":{"type":"string","maxLength":500}},"$id":"com.thrasys.xnet.xmlobjects.XeFileStorageMergeRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.xmlobjects.XeFileStorageMergeRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
