/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.xmlobjects.XeFileStorage.json#"},"ExternalID":{"type":"string","maxLength":200},"FileData":{"type":"string","maxLength":2147483647},"FileID":{"type":"integer"},"FileMAC":{"defaultValue":"*","type":"string","maxLength":60},"FileName":{"type":"string","maxLength":200},"FileSize":{"maximum":9999999999,"type":"integer"},"FileTime":{"format":"date-time","nullable":true,"type":"string"},"IsBase64":{"type":"boolean"},"IsEncrypted":{"type":"boolean"},"IsTemporary":{"type":"boolean"},"Username":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.xmlobjects.XeFileStorage.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.xmlobjects.XeFileStorage.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
