/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.workentity.XeWorkEntity.json#"},"DueDateTime":{"format":"date-time","nullable":true,"type":"string"},"RecordID":{"type":"string"},"StateOwner":{"type":"string"},"StateOwnerExpire":{"format":"date-time","nullable":true,"type":"string"},"StateOwnerName":{"type":"string"},"TokenID":{"type":"string","primaryKey":"supplied","maxLength":200},"WorkID":{"type":"integer","primaryKey":"supplied"},"WorkState":{"type":"string","primaryKey":"supplied","maxLength":200},"WorkTypeID":{"type":"string"},"WorkflowID":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.workentity.XeWorkEntity.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.workentity.XeWorkEntity.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
