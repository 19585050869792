/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json#"},"Overwrite":{"defaultValue":false,"type":"boolean"},"RecordID":{"type":"integer","primaryKey":"generated"},"StateOwner":{"type":"integer"},"StateOwnerExpire":{"format":"date-time","nullable":true,"type":"string"},"StateOwnerName":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
