/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.xmlobjects.XeQueryResponse.json#"},"AllResults":{"type":"boolean"},"NumberOfResults":{"type":"integer"},"NumberRestricted":{"type":"integer"},"RestrictProcessed":{"type":"boolean"},"Restricted":{"type":"boolean"}},"$id":"com.thrasys.xnet.xmlobjects.XeQueryResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.xmlobjects.XeQueryResponse.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
