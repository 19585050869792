/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.xmlobjects.XeFileStorageMessage.json#"},"Base64LineLength":{"type":"integer"},"CloudID":{"type":"string","maxLength":220},"Compress":{"type":"string"},"CompressedSize":{"defaultValue":0,"maximum":9999999999,"type":"integer"},"ExternalID":{"type":"string","maxLength":220},"FileContents":{"type":"string","maxLength":2147483647},"FileData":{"type":"string"},"FileID":{"type":"integer","primaryKey":"supplied"},"FileMAC":{"defaultValue":"*","type":"string","maxLength":60},"FileName":{"type":"string","maxLength":200},"FileSize":{"maximum":9999999999,"type":"integer"},"FileTime":{"format":"date-time","nullable":true,"type":"string"},"IsBase64":{"type":"boolean"},"IsEncrypted":{"defaultValue":false,"type":"boolean"},"IsFile":{"type":"string"},"IsTemporary":{"defaultValue":false,"type":"boolean"},"StorageID":{"type":"string","maxLength":200},"Username":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.xmlobjects.XeFileStorageMessage.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.xmlobjects.XeFileStorageMessage.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
