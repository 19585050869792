/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json#"},"IsOffList":{"type":"boolean"},"RecordID":{"type":"integer","primaryKey":"supplied"},"TokenID":{"type":"string","maxLength":200},"WorkID":{"type":"integer"},"WorkState":{"type":"string","maxLength":200},"WorkflowID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
