/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$ServiceID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$WorkID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.workentity.XeWorkEntity.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$XeOrderBase.json#"},"Active":{"type":"boolean"},"Code":{"type":"string","maxLength":32},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsPRN":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NextOccurrenceTime":{"type":"string"},"OccurrenceDateTime":{"format":"date-time","nullable":true,"type":"string"},"OccurrenceID":{"type":"integer"},"OrderID":{"type":"integer","primaryKey":"generated"},"ServiceID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$ServiceID.json#"},"ServiceIDInline":{"type":"string"},"ServiceTypeID":{"type":"string","maxLength":32},"WorkID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$WorkID.json#"},"XeWorkEntity":{"$ref":"com.thrasys.xnet.erp.xmlobjects.workentity.XeWorkEntity.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$XeOrderBase.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse$XeOrderBase.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
